.content_footer {
	background: #faf4ff;
	padding: 15px 0;
	font-family: 'Quicksand', sans-serif;
	font-size: 14px;
	color: #573871;
}

.content-layout {
	max-width: 1200px;
	margin: auto;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.content_info {
	display: flex;
	justify-content: space-around;
	padding-bottom: 12px;
}

.content_info .title-info {
	font-weight: 600;
	padding-bottom: 10px;
}

.derechos {
	text-align: center;
}
.redes {
	text-align: center;
}
.redes .icon-redes {
	text-align: center;
}
.icon-redes a {
	font-size: 30px;
	margin: 0 5px;
}

.footerLink {
	color: #57387e;
	font-size: 15px;
	font-weight: 500;
}

.dweb {
	font-size: 14px;
}

@media only screen and (min-width: 576px) {
	.derechos.mobile {
		display: none;
	}
}
@media only screen and (max-width: 576px) {
	.content_info {
		display: initial;
		text-align: center;
	}
	.info,
	.redes,
	.explorer {
		padding-bottom: 12px;
	}
	.content_info .title-info {
		font-weight: 600;
		padding-bottom: 4px;
	}
	.derechos.desktop {
		display: none;
	}

	.diseño {
		padding-top: 10px;
	}
}
