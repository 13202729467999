.content_home {
	height: 100%;
	display: flex;
	/* position: absolute; */
	width: 100%;
	flex-direction: column;
}

.content_home-global {
	display: flex;
	width: 100%;
	height: 80vh;
	position: relative;
}

.content_img {
	width: 60%;
	padding: 5px;
	height: 100%;
	position: relative;
	background: url('../../images/background.jpg') no-repeat;
	background-size: cover;
}

.content_detail {
	width: 40%;
	padding: 5px;
	height: 100%;
}

.logo-lettering {
	position: absolute;
	top: 33%;
	left: 49%;
}

.logo-content {
	color: white;
	font-weight: 400;
	font-size: 146px;
	background-image: url('../../images/back-letther.jpg');
	background-size: cover;
	color: transparent;
	-webkit-background-clip: text;
	font-family: 'DM Serif Display', serif;
	line-height: 116px;
}

.read-me {
	text-align: right;
	font-size: 25px;
}

.read-me a {
	border-bottom: 1px solid #573871;
	color: #573871;
}

.logo-psd {
	max-width: 445px;
}

.logo-psd img {
	width: 100%;
}

@media only screen and (max-width: 1400px) {
	.logo-lettering {
		top: 20%;
	}
	.logo-psd {
		max-width: 405px;
	}
}
@media only screen and (max-width: 960px) {
	.logo-psd {
		max-width: 356px;
	}
}
@media only screen and (max-width: 768px) {
	.logo-psd {
		max-width: 310px;
	}
	.logo-lettering {
		position: absolute;
		top: 33%;
		left: 35%;
	}
	.read-me a {
		margin-right: 20%;
	}

	.details_info-test p {
		font-size: 14px;
	}
}
@media only screen and (min-width: 576px) {
	.content_home-global.mobile {
		display: none;
	}
}

@media only screen and (max-width: 576px) {
	.content_home-global.desktop {
		display: none;
	}

	.content_home-global {
		height: auto;
	}

	.content_img {
		background-color: none;
		width: inherit;
		padding: 0;
		height: auto;
		position: relative;
		background: none;
		background-size: cover;
	}
	.content_img img {
		width: 100%;
	}
	.content_home {
		position: relative;
	}
	.logo-lettering {
		position: absolute;
		top: 33%;
		left: 46%;
		right: 7%;
	}
}
