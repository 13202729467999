.content_navbar {
	border-bottom: 1px solid #b68ada96;
}

.content-nav {
	max-width: 1200px;
	width: 100%;
	margin: auto;
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.logo {
	padding: 7px 0 2px 0;
}

a {
	text-decoration: none;
	color: #c1b3ce;
	font-size: 18px;
	font-weight: 400;
	font-family: 'Quicksand', sans-serif;
}
a:hover {
	color: #573871;
}
.content-nav ul li {
	list-style: none;
	display: inline-block;
	padding: 0 15px;
}
.menu {
	display: none;
}

.cart-icon {
	position: relative;
	margin-right: 15px;
}
.cart-icon span {
	background: crimson;
	border-radius: 20px;
	color: white;
	position: absolute;
	top: -10px;
	right: -10px;
	font-size: 10px;
	padding: 5px;
}

.navevent a {
	font-size: 22px;
}

a.current {
	padding-bottom: 4px;
	border-bottom: 1px solid #573871;
	color: #573871;
}

@media (max-width: 700px) {
	header {
		justify-content: space-between;
	}
	.content-nav ul {
		position: fixed;
		top: 0;
		left: -100%;
		width: 100%;
		height: 100vh;
		background: #987acd;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		opacity: 0.98;
		z-index: 99;
		transition: 0.5s ease-in;
	}
	.menu {
		display: block;
		cursor: pointer;
		padding: 15px;
	}
	ul li .menu {
		position: absolute;
		top: 20px;
		right: 20px;
	}

	a.current {
		padding-bottom: 4px;
		border-bottom: 1px solid #573871;
		color: white;
	}
}
