.details-products {
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.productOne {
	max-width: 373px;
	margin: auto;
	background-color: #f5eaff;
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 37px 0;
	width: 100%;
}

.detalle-product {
	padding: 0 20px;
	width: 100%;
}

@media (max-width: 1228px) {
	.details-products {
		margin: 0 15px;
	}
	.productOne {
		max-width: 373px;
		padding: 19px 17px;
	}
}

.content-button-disable {
	display: flex;
	justify-content: space-between;
	padding: 0 0px;
	padding-top: 12px;
}
