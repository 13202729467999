.layout-content {
	flex: 1;
	display: flex;
	position: relative;
}

.layout-global {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}
