.content_testimonials {
	margin: auto;
	max-width: 1200px;
	width: 100%;
}
.content-detail-test {
	display: flex;
	margin: 24px 13%;
	background-color: white;
	padding: 22px 15px;
	border-radius: 7px;
	box-shadow: -4px 6px 9px 0px #00000040;
}

.img-details-test {
	width: 391px;
	margin-right: 26px;
}
.img-details-test img {
	width: 100%;
	border-radius: 50px;
}

.testimonios {
	background-color: #faf4ff;
	font-family: 'Quicksand', sans-serif;
}

.details_info-test {
	font-style: italic;
	color: #6b5b68;
}

.title-testimonios p {
	text-align: center;
	font-size: 28px;
	padding-top: 19px;
	color: #a488bd;
	font-weight: 500;
}

@media only screen and (max-width: 576px) {
	.content-detail-test {
		display: inline-block;
	}
	.img-details-test {
		max-width: 100px;
		margin: auto;
		margin-bottom: 12px;
	}
	.details_info-test p {
		font-size: 12px;
	}
	.title-testimonios p {
		font-size: 20px;
	}
}
