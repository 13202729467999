.content_services {
	width: 100%;
	font-family: 'Quicksand', sans-serif;
	padding-bottom: 55px;
}

.content {
	display: flex;
	margin: auto;
	max-width: 1200px;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.content-imagen {
	max-width: 300px;
	overflow: hidden;
	border: 1px solid white;
}
.content-imagen img {
	width: 100%;
	border-radius: 5px;
	object-fit: cover;
	transform: scale(1);
	animation: float 8s ease-in-out infinite;
}

@keyframes float {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.15);
	}
	100% {
		transform: scale(1);
	}
}
.detalle {
	width: 100%;
}

.content-swiper {
	justify-content: center;
	text-align: center;
	display: flex;
	background: #f5eaff;
	flex-direction: column;
	text-align: center;
	align-items: center;
	padding: 25px 14px;
	margin: 10px;
	border-radius: 7px;
	transition: 0.2s;
	cursor: pointer;
}
.content-swiper:hover {
	background: #987acd;
	color: white;
	box-shadow: 4px 4px 5px #00000059;
}
.content-swiper:hover .detail-title {
	color: white;
}
.content-swiper:hover .button-whatsapp {
	background: white;
	color: #987acd;
}
.content-swiper:hover .button-whatsapp a {
	color: #987acd;
	font-weight: 600;
}
.content-swiper:hover .detail-ver {
	color: white;
	border-bottom: 1px solid white;
}

/* styles swiper */

.title-event p {
	display: inline-block;
	font-size: 36px;
	border-bottom: 2px solid #513b6f;
	color: #513b6f;
	margin: 44px 0 32px 0;
}

.detail-title {
	font-size: 20px;
	font-weight: 600;
	padding: 10px 0px 9px;
	color: #513b6f;
	justify-content: center;
	align-items: center;
	display: flex;
	height: 70px;
}
.detail-price {
	padding: 5px 0 11px;
	font-weight: 500;
}

.breve {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

.button-whatsapp {
	font-size: 20px;
	background-color: #987acd;
	padding: 8px 17px;
	border: none;
	border-radius: 8px;
	transition: 0.5s;
}
.button-whatsapp-disable {
	font-size: 20px;
	background-color: #a9a9a9;
	color: white;
	padding: 8px 17px;
	border: none;
	border-radius: 3px;
	transition: 0.5s;
}

.button-whatsapp a {
	text-decoration: none;
	color: white;
}

.button-whatsapp:hover {
	background-color: #5c3e8f;
}
.button-whatsapp:hover a {
	color: white;
}

.content-button {
	display: flex;
	justify-content: space-between;
	padding: 0;
	padding-top: 12px;
}

.content-button-disable-service {
	display: flex;
	justify-content: center;
	padding: 0 14px;
	padding-top: 12px;
}

.detail-ver {
	color: #513b6f;
	font-weight: 500;
	border-bottom: 1px solid #513b6f;
	margin-bottom: 1px;
}

.services_detail {
	display: flex;
	flex-wrap: wrap;
}
.services_detail .swiper-container {
	padding-bottom: 35px;
}

.content-all-swiper {
	width: 25%;
}

/* end styles swiper */

@media (max-width: 1228px) {
	.services_detail {
		margin: 0 15px;
	}
}
@media (max-width: 1100px) {
	.content-all-swiper {
		width: 33.333%;
	}
}
@media (max-width: 960px) {
	.content-all-swiper {
		width: 33.333%;
	}
	.button-whatsapp-disable {
		font-size: 16px;
	}
}

@media (max-width: 768px) {
	.content-all-swiper {
		width: 50%;
	}
	.title-event p {
		font-size: 35px;
		margin: 22px 0 32px 0;
	}

	.content-button {
		padding: 0 0;
		padding-top: 12px;
	}
}
@media (max-width: 600px) {
	.title-event p {
		font-size: 26px;
		margin: 16px 0 23px 0;
	}

	.detail-title {
		font-size: 18px;
		padding: 4px 0px 5px;
	}
	.content-swiper {
		padding: 14px 14px;
	}
	.content-button {
		padding: 0 0px;
		padding-top: 8px;
	}
}

@media (max-width: 576px) {
	.content-all-swiper {
		width: 100%;
	}
	.content-imagen {
		max-width: 100%;
		width: 100%;
	}
}
@media (max-width: 400px) {
	.title-event p {
		font-size: 26px;
		margin: 16px 0 23px 0;
	}
	.content-all-swiper {
		padding: 0 0%;
		margin: 0;
	}
	.detail-title {
		font-size: 18px;
		padding: 4px 0px 5px;
	}
	.content-swiper {
		padding: 14px 14px;
	}
	.content-button {
		padding: 0 0px;
		padding-top: 8px;
	}
}

@media (max-width: 320px) {
	.title-event p {
		font-size: 26px;
		margin: 16px 0 23px 0;
	}
	.content-all-swiper {
		margin: 0;
	}
	.detail-title {
		font-size: 18px;
		padding: 4px 0px 5px;
	}
	.content-swiper {
		padding: 14px 14px;
	}
}
